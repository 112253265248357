import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NewDateStep from 'components/NewDateStep/NewDateStep';
import Title from 'components/Title';

const InsuranceStartStep = ({ data, onConfirm, setUserData }) => {
  const { t } = useTranslation();
  const getNewContractDateText = () => {
    if (
      //!data.hasCurrentWarranty &&
      data.contractStartDate &&
      moment().diff(data.contractStartDate, 'month') <= -3
    )
      return t('steps.insuranceStartStep.errorMsg');
  };

  const getNewDateMin = () => {
    let minDate = moment().startOf('day');
    if (data.hasCurrentWarranty) {
      minDate = minDate.add(40, 'day');
    } else {
      minDate = minDate.add(1, 'day');
    }
    return minDate;
  };

  return (
    <>
      <Title title={t('steps.insuranceStartStep.title')} />
      <NewDateStep
        dataKey="contractStartDate"
        label={t('common.formFields.chooseDate')}
        message={
          data.from &&
          data.from.toLowerCase() === 'ttw' &&
          t('steps.insuranceStartStep.ttwSourceMessage')
        }
        onChange={setUserData}
        onConfirm={onConfirm}
        value={data.contractStartDate}
        text={getNewContractDateText()}
        minDate={getNewDateMin()}
        invalid={
          moment().diff(data.contractStartDate, 'day') > 0 || //!data.hasCurrentWarranty &&
          (data.contractStartDate &&
            moment().diff(data.contractStartDate, 'month') <= -3)
        }
      />
    </>
  );
};

InsuranceStartStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default InsuranceStartStep;
